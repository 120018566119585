
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CredentialingContent',

  components: {
    Error: defineAsyncComponent(() => import('@/error.vue')),
    PDEHeading: defineAsyncComponent(() => import('@/components/pde/PDEHeading.vue')),
    PDEHorizontalNav: defineAsyncComponent(() => import('@/components/pde/PDEHorizontalNav.vue')),
  },

  props: {
    pathPrefix: {
      type: String,
      require: true,
      default: '',
    },
  },

  data({ pathPrefix }) {
    return {
      items: [
        {
          to: `${pathPrefix}/documents/financial`,
          label: 'Paystubs',
          dataTest: 'financial-nav-paystubs',
          analyticsData: {
            clickName: 'Tab Clicked',
            clickText: 'Paystubs',
            clickArea: `${pathPrefix}documents/financial/paystubs`,
          },
        },
        {
          to: `${pathPrefix}/documents/financial/tax-documents`,
          label: '1099',
          dataTest: 'financial-nav-tax-documents',
          analyticsData: {
            clickName: 'Tab Clicked',
            clickText: '1099',
            clickArea: `${pathPrefix}documents/financial/1099`,
          },
        },
      ],
    }
  },

  computed: {
    ...mapGetters('documents', ['documentError']),
  },
}
